import {
    Button,
    Snackbar,
    Stack,
    Box,
    Typography,
    Paper,
    Slider,
    CircularProgress,
} from '@mui/material';
import React from 'react';

import { formatDuration } from '../../utills/formatDuration';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useLocation } from 'react-router-dom';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';

import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import CancelIcon from '../../components/CancelIcon';

interface Props {}

export const AuidoSnack: React.FC<Props> = () => {
    const { audioitem } = useTypedSelector((state) => state.app);
    let { pathname } = useLocation();

    const { togglePlayPause, playing, loading, ready, stop } = useAudioPlayer();
    const { duration, position, seek } = useAudioPosition();

    const handleSliderChanged = (event: Event, value: number | number[], activeThumb: number) => {
        if (ready) seek(value as number);
    };

    const invisible = pathname === '/howto' || pathname === '/auth';
    if (invisible) return null;

    return (
        <Snackbar open={position > 0 || playing} color={'primary'} sx={{ bottom: 120, my: 1, flex: 1 }}>
            <Paper
                sx={{
                    flex: 1,
                    bgcolor: '#1D1D1D',
                    boxShadow: '0px -7px 4px rgba(14, 14, 14, 0.75)',
                }}
            >
                <Stack direction='row' justifyContent='space-evenly'>
                    <Box
                        sx={{ width: 65, height: 62, m: 1 }}
                        style={{
                            backgroundImage:
                                audioitem && audioitem.thumb
                                    ? `url(${process.env.REACT_APP_API}${audioitem!.thumb.url})`
                                    : `url(/gon-logo.png)`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                        }}
                    />
                    <Stack justifyContent='center' sx={{ flex: 1 }}>
                        <Typography variant='h5' sx={{ color: 'white' }}>
                            {audioitem?.index} .{audioitem?.title}
                        </Typography>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography variant='caption' color='secondary'>
                                {formatDuration(duration)}
                            </Typography>
                            <Typography variant='caption' color='secondary'>
                                {formatDuration(position)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' sx={{ p: 0 }}>
                        <Button
                            sx={{ minWidth: 10, p: 0, color: 'white' }}
                            onClick={togglePlayPause}
                            disabled={loading}
                        >
                            {ready ? (
                                playing ? (
                                    <PauseIcon sx={{ fontSize: 34 }} />
                                ) : (
                                    <PlayIcon sx={{ fontSize: 34 }} />
                                )
                            ) : (
                                <CircularProgress size={34} />
                            )}
                        </Button>
                        <Button sx={{ minWidth: 10, p: 0 }} onClick={() => stop()}>
                            <CancelIcon sx={{ fontSize: 42 }} />
                        </Button>
                    </Stack>
                </Stack>
                <Box sx={{ position: 'relative', mx: 1 }}>
                    <Slider
                        sx={{ position: 'absolute', top: -22 }}
                        size='small'
                        min={0}
                        max={duration}
                        value={position}
                        step={1}
                        onChange={handleSliderChanged}
                        onChangeCommitted={() => {}}
                        disabled={!ready}
                        color='secondary'
                    />
                </Box>
            </Paper>
        </Snackbar>
    );
};
