import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

export const theme: ThemeOptions = responsiveFontSizes(
    createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#7a7a7a',
            },
            secondary: {
                main: '#1C4688',
            },
            background: {
                default: '#121212',
                paper: '#2D2D2D',
            },
            text: {
                primary: '#ffffff',
                disabled: 'rgba(255,255,255,0.2)',
            },
        },
        typography: {
            fontFamily: 'Oswald',
            h2: {
                fontSize: '1.6rem',
                fontWeight: 500,
                letterSpacing: '0.05em',
            },
            h3: {
                fontSize: '1.5rem',
                fontWeight: 200,
                letterSpacing: '0.05em',
            },
            h5: {
                fontSize: '1.15rem',
                fontWeight: 500,
                letterSpacing: '0.15px',
            },
            body2: {
                fontSize: '1.5rem',
                fontWeight: 200,
                //lineHeight: '1.2rem',
            },
            caption: {
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: '0.1em',
            },
        },
        components: {
            MuiSnackbarContent: {
                defaultProps: {
                    action: {
                        m: 0,
                    },
                    message: {
                        color: 'white',
                    },
                },
            },

            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#1C4688',
                        color: 'white',
                    },
                },
            },
        },
    }),
    {}
);
