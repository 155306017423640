import { Dispatch } from 'redux';
import axios from 'axios';
import { AppActionsTypes, AppActionTypes, IAudioitem } from '../../types/app';
import { RootState } from '../reducers';

export const fetchData = (token: null | string | undefined, locale: string) => {
    return async (dispatch: Dispatch<AppActionTypes>) => {
        try {
            dispatch({
                type: AppActionsTypes.FETCH_DATA,
                payload: {
                    token: token,
                    locale: locale,
                },
            });

            const api = process.env.REACT_APP_API;
            const response: any = await axios.get(`${api}/audioitems`, {
                params: {
                    _sort: 'id:ASC',
                    locale: locale,
                    // TO DO
                    //token: token,
                },
            });

            return dispatch({
                type: AppActionsTypes.FETCH_DATA_SUCCESS,
                payload: { audioitems: response.data },
            });
        } catch (e: any) {
            dispatch({
                type: AppActionsTypes.FETCH_DATA_ERROR,
                payload: { error: e.message },
            });
        }
    };
};

export const setLanguge = (locale: string) => {
    return async (dispatch: Dispatch<AppActionTypes>) => {
        dispatch({
            type: AppActionsTypes.SET_LANGUGE,
            payload: {
                locale: locale,
            },
        });
    };
};

export const setTutorialIsComplete = (isTutorialComplete: boolean) => {
    return async (dispatch: Dispatch<AppActionTypes>) => {
        dispatch({
            type: AppActionsTypes.SET_TUTORIAL_IS_COMPLETE,
            payload: {
                isTutorialComplete: isTutorialComplete,
            },
        });
    };
};

export const setAudioitem = (audioitem: IAudioitem, autoplay: boolean = false) => {
    return (dispatch: Dispatch<AppActionTypes>, getState: () => RootState) => {
        const state = getState();
        dispatch({
            type: AppActionsTypes.SET_AUDIO_ITEM,
            payload: {
                audioitem: audioitem,
                audioindex: state.app.audioitems?.indexOf(audioitem),
                autoplay: autoplay ? autoplay : false,
            },
        });
    };
};

export const nextAudioitem = () => {
    return (dispatch: Dispatch<AppActionTypes>, getState: () => RootState) => {
        const state = getState();
        let idx = state.app.audioindex!;
        idx++;

        //console.log(idx);

        dispatch({
            type: AppActionsTypes.SET_AUDIO_ITEM,
            payload: {
                audioitem: state.app.audioitems![idx],
                audioindex: idx,
                autoplay: false,
            },
        });
    };
};

export const prevAudioitem = () => {
    return (dispatch: Dispatch<AppActionTypes>, getState: () => RootState) => {
        const state = getState();
        let idx = state.app.audioindex!;
        idx--;

        dispatch({
            type: AppActionsTypes.SET_AUDIO_ITEM,
            payload: {
                audioitem: state.app.audioitems![idx],
                audioindex: idx,
                autoplay: false,
            },
        });
    };
};
