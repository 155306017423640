import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { useActions } from '../hooks/useAction';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { AudioView } from './views/AudioView';

interface Props {}

export const PlayerPage: React.FC<Props> = () => {
    //const { token } = useTypedSelector((state) => state.auth);
    const { audioitems, audioitem /*, locale*/ } = useTypedSelector((state) => state.app);

    const { setAudioitem, nextAudioitem, prevAudioitem } = useActions();

    const { slug } = useParams();

    let displayAudioitem;
    if (slug && audioitems) displayAudioitem = audioitems.find((i) => i.slug === slug);
    else if (!slug && audioitems) displayAudioitem = audioitems[0];

    useEffect(() => {
        if (!audioitem) if (audioitems) setAudioitem(audioitems[0]);
        // eslint-disable-next-line
    }, []);

    //if (!isTutorialComplete) return <Navigate to='/howto' />;

    return (
        <Layout title={`Аудиогид`}>
            <Box sx={{ mt: '110px', flex: 1 }}>
                {audioitems ? (
                    <AudioView
                        displayAudioitem={displayAudioitem}
                        audioitem={audioitem}
                        audioitems={audioitems}
                        nextAudioitem={nextAudioitem}
                        prevAudioitem={prevAudioitem}
                        setAudioitem={setAudioitem}
                    />
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </Layout>
    );
};
