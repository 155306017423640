import { AppActionsTypes, AppActionTypes, IAppState } from '../../types/app';

const initialState: IAppState = {
    locale: 'ru',
    isTutorialComplete: false,
    audioitems: null,
    audioitem: null,
    audioindex: 0,
    autoplay: false,
    loading: false,
    error: null,
};

export const appReducer = (state = initialState, action: AppActionTypes): IAppState => {
    switch (action.type) {
        case AppActionsTypes.FETCH_DATA:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case AppActionsTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                audioitems: action.payload.audioitems,
            };
        case AppActionsTypes.FETCH_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case AppActionsTypes.SET_LANGUGE:
            return {
                ...state,
                locale: action.payload.locale,
            };
        case AppActionsTypes.SET_TUTORIAL_IS_COMPLETE:
            return {
                ...state,
                isTutorialComplete: action.payload.isTutorialComplete,
            };
        case AppActionsTypes.SET_AUDIO_ITEM:
            return {
                ...state,
                audioitem: action.payload.audioitem,
                audioindex: action.payload.audioindex,
                autoplay: action.payload.autoplay,
            };
        default:
            return state;
    }
};
