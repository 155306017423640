import { Box, Stack, Paper, Typography, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAudioPlayer } from 'react-use-audio-player';

import { IAudioitem } from '../../types/app';

import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';

interface Props {
    audioitem: IAudioitem | null | undefined;
    audioitems: IAudioitem[] | null;
    displayAudioitem: IAudioitem | null | undefined;
    nextAudioitem: () => void;
    prevAudioitem: () => void;
    setAudioitem: (audioitem: IAudioitem, autoplay?: boolean) => void;
}

export const AudioView: React.FC<Props> = ({
    audioitem,
    audioitems,
    displayAudioitem,
    nextAudioitem,
    prevAudioitem,
    setAudioitem,
}) => {
    const { togglePlayPause, playing, ready } = useAudioPlayer();

    let navigate = useNavigate();
    let audioindex = audioitems?.findIndex((i) => i.slug === displayAudioitem?.slug);

    const next = audioitems![audioindex! + 1];
    const prev = audioitems![audioindex! - 1];

    return (
        <Box sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 3 } }}>
            <Typography variant='h2' sx={{ px: 1, pb: { xs: 1, sm: 3 } }}>
                {displayAudioitem?.index}. {displayAudioitem?.title}
            </Typography>
            <Paper
                sx={{
                    /*height: 370,*/
                    borderRadius: 8,
                    py: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    bgcolor: 'black',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{ width: '100%', height: { xs: 100, sm: 173 }, maxHeight: 173 }}
                    style={{
                        backgroundImage:
                            displayAudioitem && displayAudioitem.thumb
                                ? `url(${process.env.REACT_APP_API}${displayAudioitem!.thumb.url})`
                                : `url(/gon-logo.png)`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                    }}
                />

                {displayAudioitem === audioitem ? (
                    <Stack direction='row' alignItems='center' justifyContent='space-evenly'>
                        <Button
                            sx={{ color: 'white' }}
                            disabled={!ready || !prev}
                            onClick={() => {
                                prevAudioitem();
                                navigate(`/${prev.slug}`);
                            }}
                        >
                            <FastRewindRounded sx={{ fontSize: '4rem' }} />
                        </Button>
                        <Button
                            disabled={!ready}
                            sx={{ color: 'white' }}
                            onClick={() => {
                                // setAudioitem(displayAudioitem!);
                                togglePlayPause();
                            }}
                        >
                            {ready ? (
                                playing ? (
                                    <PauseIcon sx={{ fontSize: 76 }} />
                                ) : (
                                    <PlayIcon sx={{ fontSize: 76 }} />
                                )
                            ) : (
                                <CircularProgress size={56} />
                            )}
                        </Button>
                        <Button
                            sx={{ color: 'white' }}
                            disabled={!ready || !next}
                            onClick={() => {
                                nextAudioitem();
                                navigate(`/${next.slug}`);
                            }}
                        >
                            <FastForwardRounded sx={{ fontSize: '4rem' }} />
                        </Button>
                    </Stack>
                ) : (
                    <Stack direction='row' alignItems='center' justifyContent='space-evenly'>
                        <Button />
                        <Button
                            sx={{ color: 'white' }}
                            onClick={() => {
                                setAudioitem(displayAudioitem!, true);
                            }}
                        >
                            <PlayIcon sx={{ fontSize: '5rem' }} />
                        </Button>
                        <Button />
                    </Stack>
                )}

                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 3 }}>
                    {prev ? (
                        <Button onClick={() => navigate(`/${prev.slug}`)}>{prev && prev.title}</Button>
                    ) : (
                        <div />
                    )}
                    {next ? (
                        <Button onClick={() => navigate(`/${next.slug}`)}>{next && next.title}</Button>
                    ) : (
                        <div />
                    )}
                </Stack>

                {/* <Backdrop open={!ready}>
                    <CircularProgress size={180} sx={{ opacity: 0.5 }} />
                </Backdrop> */}
            </Paper>
        </Box>
    );
};
