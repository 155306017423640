import {
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import BackIcon from '../components/BackIcon';
import { Layout } from '../components/Layout';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { useActions } from '../hooks/useAction';
import { useNavigate } from 'react-router-dom';

import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import { formatDuration } from '../utills/formatDuration';
import { useAudioPlayer } from 'react-use-audio-player';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

interface Props {}

export const ListPage: React.FC<Props> = () => {
    const { audioitems, audioitem, audioindex } = useTypedSelector((state) => state.app);
    let navigate = useNavigate();
    const { setAudioitem } = useActions();
    const { togglePlayPause, ready, playing } = useAudioPlayer();
    const { height } = useWindowDimensions();

    const handleListItemClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number) => {
        if (i === audioindex) {
            togglePlayPause();
        } else {
            setAudioitem(audioitems![i], true);
        }
    };

    let fH = 110;
    let hH = 110;
    let htH = 60;

    let listHeight = height - fH - hH - htH;

    return (
        <Layout title={`Список`}>
            <Box sx={{ mt: '110px', flex: 1 }}>
                <Stack
                    sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 3 } }}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography variant='h2'>{`Список экспонатов`}</Typography>
                    <Button onClick={() => navigate(`/${audioitem?.slug}`)}>
                        <BackIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem' } }} />
                    </Button>
                </Stack>
                <List sx={{ pb: '110px', overflow: 'scroll', maxHeight: listHeight }}>
                    {audioitems?.map((i, index) => (
                        <>
                            <Divider component='li' key={i.slug + 'li'} />
                            <ListItemButton key={i.slug} onClick={(e) => handleListItemClicked(e, index)}>
                                <ListItemAvatar sx={{ mr: 2 }}>
                                    <Box
                                        sx={{ width: 60, height: 60 }}
                                        style={{
                                            backgroundImage:
                                                i && i.thumb
                                                    ? `url(${process.env.REACT_APP_API}${i!.thumb.url})`
                                                    : `url(/gon-logo.png)`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                        }}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={`${i.index}.   ${i.title}`}
                                    secondary={
                                        <Typography variant='caption' color='secondary'>{`${formatDuration(
                                            i.audio.duration
                                        )}`}</Typography>
                                    }
                                />
                                <ListItemIcon>
                                    {audioindex === index ? (
                                        ready ? (
                                            playing ? (
                                                <PauseIcon sx={{ fontSize: 34 }} />
                                            ) : (
                                                <PlayIcon sx={{ fontSize: 34 }} />
                                            )
                                        ) : (
                                            <CircularProgress size={34} />
                                        )
                                    ) : (
                                        <PlayIcon sx={{ fontSize: 34 }} />
                                    )}
                                </ListItemIcon>
                            </ListItemButton>
                        </>
                    ))}
                    <Divider component='li' />
                </List>
            </Box>
        </Layout>
    );
};
