export enum AuthActionsTypes {
    AUTHORISE_DEVICE = 'AUTHORISE_DEVICE',
    AUTHORISE_DEVICE_SUCCESS = 'AUTHORISE_DEVICE_SUCCESS',
    AUTHORISE_DEVICE_ERROR = 'AUTHORISE_DEVICE_ERROR',
}

export interface IAuthoriseDeviceAction {
    type: AuthActionsTypes.AUTHORISE_DEVICE;
    payload: {
        uuid: string;
        token: string | null | undefined;
    };
}

export interface IAuthoriseDeviceSuccessAction {
    type: AuthActionsTypes.AUTHORISE_DEVICE_SUCCESS;
    payload: {
        uuid: string;
        token: string | null | undefined;
    };
}

export interface IAuthoriseDeviceErrorAction {
    type: AuthActionsTypes.AUTHORISE_DEVICE_ERROR;
    payload: string;
}

export type AuthActionTypes =
    | IAuthoriseDeviceAction
    | IAuthoriseDeviceSuccessAction
    | IAuthoriseDeviceErrorAction;

export interface IAuthState {
    token: string | null | undefined;
    uuid: string | null | undefined;
    loading: boolean;
    error: string | null;
}
