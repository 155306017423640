import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import { Layout } from '../components/Layout';

import BackIcon from '../components/BackIcon';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { useNavigate } from 'react-router-dom';

import { MapView } from './views/MapView';

interface Props {}

export const NavigationPage: React.FC<Props> = () => {
    const { audioitem } = useTypedSelector((state) => state.app);
    let navigate = useNavigate();

    const ref = useRef<HTMLInputElement>(null);

    return (
        <Layout title={`Схема`}>
            <Box sx={{ mt: '110px', flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Stack
                    sx={{ px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 3 } }}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    ref={ref}
                >
                    <Typography variant='h2'>{`Схема`}</Typography>
                    <Button onClick={() => navigate(`/${audioitem?.slug}`)}>
                        <BackIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem' } }} />
                    </Button>
                </Stack>
                <MapView />
            </Box>
        </Layout>
    );
};
