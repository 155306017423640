import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

interface Props {
    title: string;
    children?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ title, children }) => {
    //const { t } = useTranslation();
    const dimensions = useWindowDimensions();

    const location = useLocation();
    useEffect(() => {
        //@ts-ignore
        window?.ym(88847852, 'hit', location.pathname);
        //console.log(location.pathname);
    }, [location]);

    return (
        <div style={{ height: `${dimensions.height}px`, display: 'flex' }}>
            <Helmet>
                <meta charSet='utf-8' />
                {/* <title>{`${t('site.title')} | ${t(props.sub)}`}</title> */}
                <title>ГОН | {title}</title>
            </Helmet>
            {children}
        </div>
    );
};
