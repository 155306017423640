import { SvgIcon } from '@mui/material';
import React from 'react';

export const CancelIcon: React.FC<any> = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.2426 7.75741C15.5397 7.05447 14.4 7.05447 13.6971 7.75741L12 9.45452L10.3029 7.75746C9.59995 7.05452 8.46026 7.05452 7.75731 7.75746C7.05437 8.4604 7.05437 9.6001 7.75731 10.303L9.45437 12.0001L7.75736 13.6971C7.05442 14.4001 7.05442 15.5398 7.75736 16.2427C8.46031 16.9456 9.6 16.9456 10.3029 16.2427L12 14.5457L13.697 16.2427C14.4 16.9457 15.5396 16.9457 16.2426 16.2427C16.9455 15.5398 16.9455 14.4001 16.2426 13.6972L14.5455 12.0001L16.2426 10.303C16.9456 9.60005 16.9456 8.46036 16.2426 7.75741Z'
                fill='#1C4688'
            />
        </SvgIcon>
    );
};

export default CancelIcon;
