import { SvgIcon } from '@mui/material';
import React from 'react';

export const BackIcon: React.FC<any> = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z'
                fill='#1C4688'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.9926 17.3033L6.21967 12.5303C5.92678 12.2374 5.92678 11.7625 6.21967 11.4696L10.9926 6.69668C11.2855 6.40379 11.7604 6.40379 12.0533 6.69668C12.3462 6.98957 12.3462 7.46444 12.0533 7.75734L8.56066 11.25L17.25 11.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75L8.56066 12.75L12.0533 16.2426C12.3462 16.5355 12.3462 17.0104 12.0533 17.3033C11.7604 17.5962 11.2855 17.5962 10.9926 17.3033Z'
                fill='white'
            />
        </SvgIcon>
    );
};

export default BackIcon;
