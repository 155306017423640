export enum AppActionsTypes {
    FETCH_DATA = 'FETCH_DATA',
    FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
    FETCH_DATA_ERROR = 'FETCH_DATA_ERROR',
    SET_LANGUGE = 'SET_LANGUGE',
    SET_TUTORIAL_IS_COMPLETE = 'SET_TUTORIAL_IS_COMPLETE',
    SET_AUDIO_ITEM = 'SET_AUDIO_ITEM',
    TOGGLE_PLAY = 'TOGGLE_PLAY',
    TOGGLE_PAUSE = 'TOGGLE_PAUSE',
}

export type AppActionTypes =
    | IFetchDataAction
    | IFetchDataSuccess
    | IFetchDataError
    | ISetLanguge
    | ISetTutorialIsComplete
    | ISetAudioItem;

export interface IFetchDataAction {
    type: AppActionsTypes.FETCH_DATA;
    payload: {
        token: string | null | undefined;
        locale: string;
    };
}

export interface IFetchDataSuccess {
    type: AppActionsTypes.FETCH_DATA_SUCCESS;
    payload: {
        audioitems: IAudioitem[];
    };
}

export interface IFetchDataError {
    type: AppActionsTypes.FETCH_DATA_ERROR;
    payload: {
        error: string;
    };
}

export interface ISetLanguge {
    type: AppActionsTypes.SET_LANGUGE;
    payload: {
        locale: string;
    };
}

export interface ISetTutorialIsComplete {
    type: AppActionsTypes.SET_TUTORIAL_IS_COMPLETE;
    payload: {
        isTutorialComplete: boolean;
    };
}

export interface ISetAudioItem {
    type: AppActionsTypes.SET_AUDIO_ITEM;
    payload: {
        audioitem: IAudioitem;
        audioindex: number | undefined;
        autoplay: boolean;
    };
}

export interface IAudioitem {
    title: string;
    slug: string;
    index: string;
    audio: IAudio;
    thumb: IImage;
    map?: IVector2;
}

export interface IAudio {
    id: number;
    name: string;
    alternativeText: string;
    url: string;
    duration: number;
}

export interface IImage {
    id: number;
    url: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
}

export interface IAppState {
    locale: string;
    isTutorialComplete: boolean;
    audioitems: IAudioitem[] | null;
    audioitem: IAudioitem | null;
    audioindex: number | undefined;
    autoplay: boolean;
    loading: boolean;
    error: string | null;
}

export interface IVector2 {
    x: number;
    y: number;
    placement:
        | 'left'
        | 'right'
        | 'bottom'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
}
