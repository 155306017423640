import React from 'react';
import { Box } from '@mui/material';

interface Props {}

export const GONLogoVert: React.FC<Props> = () => {
    return (
        <Box sx={{ flexDirection: 'column', width: '137px', alignItems: 'ceneter' }}>
            <img src={`/gon-logo.png`} alt='Гараж Особого Назначения' />
            <img src={`/gon-logo-title.svg`} alt='Гараж Особого Назначения' />
        </Box>
    );
};
