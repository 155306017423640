import { AuthActionsTypes, AuthActionTypes, IAuthState } from '../../types/auth';

const initialState: IAuthState = {
    token: '1', // < Replace null
    uuid: null,
    loading: false,
    error: null,
};

export const authReducer = (state = initialState, action: AuthActionTypes): IAuthState => {
    switch (action.type) {
        case AuthActionsTypes.AUTHORISE_DEVICE:
            return {
                ...state,
                loading: true,
                error: null,
                uuid: action.payload.uuid,
                token: action.payload.token,
            };
        case AuthActionsTypes.AUTHORISE_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                uuid: action.payload.uuid,
                token: action.payload.token,
            };
        case AuthActionsTypes.AUTHORISE_DEVICE_ERROR:
            return { ...state, loading: false, error: action.payload, token: null };
        default:
            return state;
    }
};
