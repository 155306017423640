import React from 'react';
import { useSpring, a } from '@react-spring/web';

interface Props {
    active: boolean;
}

export const CarSpring: React.FC<Props> = ({ active }) => {
    const styles = useSpring({
        transform: active ? 'translateX(0)' : 'translateX(-100%)',
    });

    return <a.img src={`/tut-car.png`} alt='car' style={{ ...styles, width: '100%' }} />;
};
