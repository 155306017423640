import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';

interface Props {
    title?: string;
}

export const Error: React.FC<Props> = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ErrorIcon sx={{ fontSize: 120 }} />
            <Typography variant='h3' sx={{ m: 2 }}>
                {props.title}
            </Typography>
        </Box>
    );
};
