import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';

import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useAction';

interface Props {
    message: string;
    onClick: () => void;
}

export const TutPlay: React.FC<Props> = ({ message, onClick }) => {
    const { audioitems } = useTypedSelector((state) => state.app);
    const { setAudioitem } = useActions();
    const { togglePlayPause, playing, ready } = useAudioPlayer();
    const { position, duration } = useAudioPosition();

    const handlePlayClick = () => {
        setAudioitem(audioitems![0], true);
        togglePlayPause();
        onClick();
    };

    const positionToPercent = (x: number, d: number) => (100 / d) * x;

    return (
        <Box>
            <Box sx={{ mb: 6, textAlign: 'center', position: 'relative' }}>
                <Box
                    style={{
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        transformOrigin: 'center',
                        top: '50%',
                        left: '50%',
                    }}
                >
                    <CircularProgress value={100} variant='determinate' size={230} thickness={1.5} />
                </Box>
                <Box
                    style={{
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        transformOrigin: 'center',
                        top: '50%',
                        left: '50%',
                    }}
                >
                    <CircularProgress
                        value={positionToPercent(position, duration)}
                        variant={playing ? 'determinate' : 'indeterminate'}
                        size={230}
                        thickness={1.6}
                        color='secondary'
                    />
                </Box>
                <Button onClick={handlePlayClick} disabled={false}>
                    {playing ? (
                        ready ? (
                            <PauseIcon sx={{ fontSize: 154 }} />
                        ) : (
                            <CircularProgress size={134} />
                        )
                    ) : (
                        <PlayIcon sx={{ fontSize: 154 }} />
                    )}
                </Button>
            </Box>
            <Typography variant='body2'>{message}</Typography>
        </Box>
    );
};
