import { useSpring, a } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import { clamp } from 'lodash-es';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Avatar, Button, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material';
import { IAudioitem } from '../../types/app';
import { useNavigate } from 'react-router-dom';

interface IMapView {}

export const MapView: React.FC<IMapView> = ({}) => {
    const { audioitems, audioitem /*, locale*/ } = useTypedSelector((state) => state.app);

    useEffect(() => {
        const handler = (e: any) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    const [ref, bounds] = useMeasure();
    const [ref1, bounds1] = useMeasure();
    const [spring, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: 0,
    }));

    const bind = useGesture(
        {
            onDrag: ({ pinching, cancel, movement: [dx, dy], offset: [x, y] }) => {
                if (pinching) return cancel();
                api.start({
                    x: clamp(
                        spring.x.get() + dx,
                        (1654 * spring.scale.get() - bounds.width) / -2,
                        (1654 * spring.scale.get() - bounds.width) / 2
                    ),
                    y: clamp(
                        spring.y.get() + dy,
                        (797 * spring.scale.get() + 200 - bounds.height) / -2,
                        (797 * spring.scale.get() + 200 - bounds.height) / 2
                    ),
                });
            },
            onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
                if (first) {
                    const { width, height, x, y } = bounds1;
                    const tx = ox - (x + width / 2);
                    const ty = oy - (y + height / 2);
                    memo = [spring.x.get(), spring.y.get(), tx, ty];
                }

                const x = memo[0] - ms * memo[2];
                const y = memo[1] - ms * memo[3];
                api.start({ scale: s, x: x * s, y: y * s });
                return memo;
            },
        },
        {
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
            eventOptions: {
                passive: false,
            },
        }
    );

    const [tooltip, setTooltip] = useState<IAudioitem | null>(null);
    const navigate = useNavigate();
    const handleTooltipClose = () => setTooltip(null);
    const handleTooltipOpen = (item: IAudioitem) => setTooltip(item);

    return (
        <div
            {...bind()}
            ref={ref}
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                background: 'black',
                touchAction: 'none',
            }}
        >
            <a.div
                ref={ref1}
                style={{
                    position: 'absolute',
                    width: 1654,
                    height: 797,
                    background: 'url(/schema.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    ...spring,
                }}
            >
                {audioitems?.map((item, i) => (
                    <MapItem
                        key={`tooltip_${item.title}`}
                        item={item}
                        handleTooltipClose={handleTooltipClose}
                        handleTooltipOpen={handleTooltipOpen}
                    />
                ))}
                {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div> */}
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltip !== null}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    sx={{ maxWidth: 500 }}
                    placement={tooltip?.map?.placement}
                    title={
                        <div style={{ textAlign: 'center' }}>
                            {/* <Avatar
                                variant='rounded'
                                alt={tooltip?.title}
                                src={
                                    tooltip && tooltip.thumb
                                        ? `${process.env.REACT_APP_API}${tooltip!.thumb.url}`
                                        : `/gon-logo.png`
                                }
                            /> */}
                            <Typography sx={{ py: 1 }}>{tooltip?.title}</Typography>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => navigate(`/${tooltip?.slug}`)}
                            >
                                Перейти
                            </Button>
                        </div>
                    }
                    arrow
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltip?.map?.x,
                            top: tooltip?.map?.y,
                            transform: 'translate(-50%, -50%)',
                            width: 40,
                            height: 40,
                        }}
                    ></div>
                </Tooltip>
                {/* </div>
                </ClickAwayListener> */}
            </a.div>
        </div>
    );
};

interface IMapItem {
    item: IAudioitem;
    handleTooltipOpen: (item: IAudioitem) => void;
    handleTooltipClose: () => void;
}

const MapItem: React.FC<IMapItem> = ({ item, handleTooltipOpen, handleTooltipClose }) => {
    return (
        <IconButton
            sx={{
                position: 'absolute',
                left: item.map?.x,
                top: item.map?.y,
                transform: 'translate(-50%, -50%)',
                display: item.map === null ? 'none' : 'inline-flex',
            }}
            onClick={() => handleTooltipOpen(item)}
        >
            <Avatar sx={{ fontSize: 9, width: 20, height: 20, border: '1px solid white' }}>
                {item.index}
            </Avatar>
        </IconButton>
    );
};
