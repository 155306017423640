import React from 'react';
import { Select, SelectChangeEvent, FormControl, MenuItem } from '@mui/material';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { useActions } from '../hooks/useAction';

interface Props {}

export const SelectLng: React.FC<Props> = () => {
    const { locale } = useTypedSelector((state) => state.app);
    const { setLanguge } = useActions();

    const handleSelectChange = (event: SelectChangeEvent) => {
        setLanguge(event.target.value);
    };

    return (
        <FormControl variant='standard' sx={{ m: 1 }}>
            <Select id='select-lng' value={locale} onChange={handleSelectChange} autoWidth disableUnderline>
                <MenuItem value='ru'>RU</MenuItem>
                {/* <MenuItem value='en'>EN</MenuItem>
                <MenuItem value='zh'>中文</MenuItem> */}
            </Select>
        </FormControl>
    );
};
