import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';

import { Provider } from 'react-redux';
import { store } from './store';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';

import { AudioPlayerProvider } from 'react-use-audio-player';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AudioPlayerProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </AudioPlayerProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
