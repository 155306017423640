import { BottomNavigation, BottomNavigationAction, Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import MapIcon from '@mui/icons-material/MapRounded';
import ListIcon from '@mui/icons-material/ListRounded';
import QrIcon from '@mui/icons-material/QrCodeScannerRounded';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import { useActions } from '../hooks/useAction';

interface Props {}

export const Footer: React.FC<Props> = () => {
    let navigate = useNavigate();
    let { pathname } = useLocation();
    const { setTutorialIsComplete } = useActions();
    const invisible = pathname === '/howto' || pathname === '/auth';
    if (invisible) return null;

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                height: 110,
                display: 'flex',
                bgcolor: 'red',
            }}
            elevation={3}
        >
            <BottomNavigation showLabels sx={{ flex: 1, height: '100%', pt: 0, alignItems: 'center' }}>
                <BottomNavigationAction
                    label={<Typography variant='caption'>{`Схема`}</Typography>}
                    arai-label={`Схема`}
                    icon={<MapIcon sx={{ fontSize: '2.4rem', mb: 1 }} />}
                    sx={{ color: 'white', height: '100%' }}
                    onClick={() => navigate('/map')}
                />
                <BottomNavigationAction
                    label={<Typography variant='caption'>{`Список\r\nэкспонатов`}</Typography>}
                    arai-label={`Список экспонатов`}
                    icon={<ListIcon sx={{ fontSize: '2.4rem', mb: 0 }} />}
                    sx={{ color: 'white', height: '100%' }}
                    onClick={() => navigate('/list')}
                />
                {/* <BottomNavigationAction
                    label={<Typography variant='caption'>{`Сканировать`}</Typography>}
                    arai-label={`Сканировать`}
                    icon={<QrIcon sx={{ fontSize: '2.4rem', mb: 1 }} />}
                    sx={{ color: 'white', height: '100%' }}
                    onClick={() => navigate('/scan')}
                /> */}
                <BottomNavigationAction
                    label={<Typography variant='caption'>{`Помощь`}</Typography>}
                    arai-label={`Помощь`}
                    icon={<HelpIcon sx={{ fontSize: '2.4rem', mb: 1 }} />}
                    sx={{ color: 'white', height: '100%' }}
                    onClick={() => {
                        setTutorialIsComplete(false);
                        navigate('/howto');
                    }}
                />
            </BottomNavigation>
        </Paper>
    );
};
