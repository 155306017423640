import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SelectLng } from './SelectLng';

interface Props {}

export const Header: React.FC<Props> = () => {
    let { pathname } = useLocation();
    const invisible = pathname === '/howto' || pathname === '/auth';
    if (invisible) return null;
    return (
        <AppBar
            sx={{
                height: 110,
                background: 'linear-gradient(180deg, #2D2D2D 0%, rgba(18, 18, 18, 0.5) 100%)',
            }}
        >
            <Toolbar sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
                <img src='/apb-gon-logo.png' alt='gon-logo' />
                <img src='/apb-gob-title.svg' alt='gon-title' />
                <SelectLng />
            </Toolbar>
        </AppBar>
    );
};
