import { Dispatch } from 'redux';
import axios from 'axios';
import { AuthActionsTypes, AuthActionTypes } from '../../types/auth';

export const authoriseDevice = (uuid: string, token: null | string | undefined) => {
    return async (dispatch: Dispatch<AuthActionTypes>) => {
        try {
            dispatch({
                type: AuthActionsTypes.AUTHORISE_DEVICE,
                payload: {
                    uuid: uuid,
                    token: token,
                },
            });
            const api = process.env.REACT_APP_API;
            const response: any = await axios.post(`${api}/strapi-qr-auth/auth`, {
                token: token,
                uuid: uuid,
            });

            return dispatch({
                type: AuthActionsTypes.AUTHORISE_DEVICE_SUCCESS,
                payload: {
                    uuid: response.data.uuid,
                    token: response.data.token ? response.data.token.token : response.data.today,
                },
            });

            // return dispatch({
            //     type: AuthActionsTypes.AUTHORISE_DEVICE_SUCCESS,
            //     payload: { uuid: uuid, token: '1234' },
            // });
        } catch (e: any) {
            dispatch({
                type: AuthActionsTypes.AUTHORISE_DEVICE_ERROR,
                payload: e.message,
            });
        }
    };
};
