import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useParams,
    useLocation,
} from 'react-router-dom';
import { useActions } from './hooks/useAction';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useAudioPlayer } from 'react-use-audio-player';
import { AuthPage } from './containers/AuthPage';
import { PlayerPage } from './containers/PlayerPage';
import { TutorialPage } from './containers/TutorialPage';
import { NavigationPage } from './containers/NavigationPage';
import { ListPage } from './containers/ListPage';
import { ScanPage } from './containers/ScanPage';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { AuidoSnack } from './containers/views/AuidoSnack';

interface Props {}

export const App: React.FC<Props> = () => {
    const { uuid, token } = useTypedSelector((store) => store.auth);

    const params = useParams();

    const isAuthenticated =
        uuid != null &&
        uuid !== undefined &&
        uuid !== '' &&
        token != null &&
        token !== undefined &&
        token !== '';

    const { audioitem, audioitems, locale, autoplay } = useTypedSelector((store) => store.app);

    useAudioPlayer({
        src: `${process.env.REACT_APP_API}${audioitem?.audio.url}`,
        html5: true,
        format: ['mp3'],
        autoplay: autoplay,
    });

    const { fetchData } = useActions();

    useEffect(() => {
        if (isAuthenticated)
            if (!audioitems) {
                fetchData(token, locale);
            }
        // eslint-disable-next-line
    }, [isAuthenticated]);



    // https://reacttraining.com/blog/react-router-v6-pre/#introducing-routes

    return (
        <Router>
            <Header />
            <Routes>
                <Route path={`/auth`} element={<AuthPage />} />
                <Route
                    path={`/howto`}
                    element={
                        isAuthenticated ? (
                            <TutorialPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ from: '/howto', token: params!.token }} />
                        )
                    }
                />
                <Route
                    path={`/map`}
                    element={
                        isAuthenticated ? (
                            <NavigationPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ from: '/map', token: params!.token }} />
                        )
                    }
                />
                <Route
                    path={`/list`}
                    element={
                        isAuthenticated ? (
                            <ListPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ from: '/list', token: params!.token }} />
                        )
                    }
                />

                {/* <Route
                    path={`/scan`}
                    element={
                        isAuthenticated ? (
                            <ScanPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ from: '/scan', token: params!.token }} />
                        )
                    }
                /> */}
                <Route
                    path={`/`}
                    element={
                        isAuthenticated ? (
                            <PlayerPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ token: params!.token }} />
                        )
                    }
                />
                <Route
                    path={`/:slug`}
                    element={
                        isAuthenticated ? (
                            <PlayerPage />
                        ) : (
                            <Navigate to={`/auth`} state={{ from: 'item', token: params!.token }} />
                        )
                    }
                />
            </Routes>
            <AuidoSnack />
            <Footer />
        </Router>
    );
};
