import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { GONLogoVert } from '../components/GONLogoVert';
import { Layout } from '../components/Layout';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';

import { useActions } from '../hooks/useAction';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { DeviceUUID } from 'device-uuid';
import * as qs from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {}

interface ILocationState {
    from?: string;
    token?: string;
}

export const AuthPage: React.FC<Props> = () => {
    const { token, uuid, loading, error } = useTypedSelector((state) => state.auth);
    const { authoriseDevice } = useActions();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const _uuid = new DeviceUUID().get();
        const _token = qs.parse(location.search).token?.toString();

        authoriseDevice(_uuid, _token);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            uuid != null &&
            uuid !== undefined &&
            uuid !== '' &&
            token != null &&
            token !== undefined &&
            token !== ''
        ) {
            if ((location.state as ILocationState)?.from) {
                navigate(-1);
            } else {
                navigate('/howto');
            }
        }
        // eslint-disable-next-line
    }, [uuid, token]);

    return (
        <Layout title={'Авторизация'}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexGrow: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}
            >
                <GONLogoVert />
                {loading && <Loading title='Идет авторизация....' />}
                {error && <Error title='Ошибка авторизации...' />}
                {}
                <Box>
                    <Typography variant='h2' style={{ textTransform: 'uppercase' }}>
                        <br />
                        <br />
                        Аудиогид
                    </Typography>
                </Box>
                <Box
                    sx={{ position: 'absolute', width: '100vw', height: 369, bottom: 20, zIndex: '-1' }}
                    style={{
                        backgroundImage: `linear-gradient(360deg, rgba(18, 18, 18, 0.8) 5.84%, rgba(0, 0, 0, 0) 23.85%), linear-gradient(180deg, #121212 3.92%, rgba(18, 18, 18, 0) 50.05%), url(/bgr-auth.jpg)`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                ></Box>
            </Box>
        </Layout>
    );
};
