import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography, Button, Container } from '@mui/material';
import { Layout } from '../components/Layout';
import { TutPlay as Play } from '../components/tutorial/TutPlay';
import { TutQR as QR } from '../components/tutorial/TutQR';
import { TutFinal as Final } from '../components/tutorial/TutFinal';

import CircleIcon from '@mui/icons-material/FiberManualRecordRounded';

import SwipeableViews from 'react-swipeable-views';
import { useActions } from '../hooks/useAction';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';

interface Props {}

export const TutorialPage: React.FC<Props> = () => {
    const { isTutorialComplete } = useTypedSelector((state) => state.app);
    const { setTutorialIsComplete } = useActions();
    const { ref, inView } = useInView();

    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const steps = useMemo(() => Array.from(Array(3).keys()), []);

    const handleSubmitClick = () => {
        setTutorialIsComplete(true);
        navigate('/');
    };

    useEffect(() => {
        setTutorialIsComplete(false);
        // eslint-disable-next-line
    }, []);

    if (isTutorialComplete) return <Navigate to='/' />;

    return (
        <Layout title={`Инструкция`}>
            <Box
                sx={{
                    textAlign: 'center',
                    overflowY: 'scroll',
                    display: 'flex',

                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Box maxWidth='sm'>
                    <Box sx={{ py: 8 }}>
                        <Typography variant='h2' sx={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
                            {`Добро пожаловать\r\nв аудиогид музея\r\n«Гараж особого\r\n назначения»`}
                        </Typography>
                    </Box>
                    <Box sx={{ py: 4 }}>
                        <Play message={`Пожалуйста, прослушайте вступление`} onClick={handleSubmitClick} />
                    </Box>
                    {/* <Box sx={{ py: 4 }}>
                        <QR message={`Сканируйте QR-коды\r\nдля перехода к нужному\r\nэкспонату `} />
                    </Box> */}
                    <Box sx={{ py: 4 }} ref={ref}>
                        <Final message={`ПОЕХАЛИ!`} active={inView} onClick={handleSubmitClick} />
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};
