import { Box, Button } from '@mui/material';
import React from 'react';
import { CarSpring } from './CarSpring';

interface Props {
    message: string;
    active: boolean;
    onClick: () => void;
}

export const TutFinal: React.FC<Props> = ({ message, active, onClick }) => {
    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Button color='secondary' variant='contained' size='large' onClick={onClick}>
                {message}
            </Button>
            <Box
                sx={{
                    mb: 6,
                    height: 230,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CarSpring active={active} />
            </Box>
        </Box>
    );
};
