export const formatDuration = (value: number | undefined) => {
    if (value) {
        const minute = Math.floor(value / 60);
        const secondLeft = Math.floor(value - minute * 60);

        return `${minute.toFixed()}:${secondLeft < 10 ? `0${secondLeft.toFixed()}` : secondLeft.toFixed()}`;
    }

    return `00:00`;
};
